:root {
  --white-color: #ffffff;
  --black-color: #000000;
  --company-color: #aa7d03;
  --primary-color: #e0e0e0;
  --secondary-color: #333333;
  user-select: none;
}

h1 {
  font-family: "Oswald", sans-serif;
  color: var(--black-color);
}

h2 {
  color: var(--company-color);
  font-weight: bold;
  padding-left: 30px;
  font-size: 30px;
}

h3 {
  font-family: "Montserrat", sans-serif;
  color: var(--company-color);
  padding-left: 30px;
  font-size: 1.25rem;
}

p {
  font-family: "Montserrat", sans-serif;
  padding-left: 30px;
  padding-right: 20px;
  color: var(--black-color);
}
a {
  color: var(--company-color);
}

body {
  margin: 0;
  min-height: 1px;
  background-color: var(--white-color);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  line-height: 1.5;
}

main {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;
}
/* Checkbox-Styling */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid var(--company-color);
  outline: none;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

input[type="checkbox"]::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  background-color: var(--white-color);
  border-radius: 2px;
}

input[type="checkbox"]:checked::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  background-color: var(--company-color);
  border-radius: 2px;
  color: var(--company-color);
}
